<template>
  <div class="p-grid">
    <div :style="expandSidebarDialog ? 'width:70rem;' : 'width:20rem;'"
         style="margin: 10px;box-shadow: 1px 1px 1px lightgrey; font-size: 12px; background: #fff">
      <div style="padding-right: 1.5rem;padding-top: 1.5rem;padding-left: 1.5rem;">
        <h3 class="p-m-0" style="line-height: .9">
          <Button
              v-tooltip="expandSidebarDialog ? _t('label_List_view') : _t('label_Table_view')"
              :icon="expandSidebarDialog ? 'mdi mdi-table-arrow-left' : 'mdi mdi-table-arrow-right'"
              class="p-button-rounded p-button-icon-only p-button-text"
              style="float: right;"
              @click="expandSidebarDialog = !expandSidebarDialog"
          />
          <Button v-if="!(isEditable || isDeleted)" v-tooltip.bottom="_t('menu.alarm_definition.new')"
                  class="p-button-rounded p-button-success p-button-text"
                  icon="pi pi-plus" style="float: right;" @click="initNewAlarmPointDialog()"/>
          <Button v-tooltip="childFilter ? _t('label_exclude_child_orgs') : _t('label_include_child_orgs')"
                  :icon="childFilter ? 'medium-icon mdi mdi-account-multiple-minus' : 'medium-icon mdi mdi-account-multiple-plus'"
                  class="p-button-rounded p-button-icon-only p-button-text"
                  style="float: right;"
                  @click="childFilter = !childFilter"/>
          <strong>{{ isDeleted ? _t('menu.deleted_alarm_points') : _t('menu.alarm_definition.new') }}</strong>
        </h3>
        <br>
        <MultiSelectableBreadcrumb
            ref="breadcrumbData"
            :tree-params="treeParams"
            single-selection>
        </MultiSelectableBreadcrumb>
        <div style="padding-top: 10px"></div>
        <div>
          <SelectButton
              v-model="selectedTab"
              :disabled="selectorIsLoading"
              :options="tabs"
              class="alarm-definition__tabs"
              optionValue="value"
              @change="getAlarmPoints"
          >
            <template #option="slotProps">
              <p style="font-size: 10px !important; margin: 0;">{{ _t(slotProps.option.label) }}</p>
            </template>
          </SelectButton>
        </div>
        <div v-if="listFiltersEnabled" style="padding: 5px;">
          <span style="color: #82B541;">{{ _t('label_object_name') }} {{ listFilters.name }}</span><br/>
          <span style="color: #82B541;">{{ _t('label_system_id') }} {{ listFilters.systemId }}</span><br/>
          <span style="color: #82B541;">{{ _t('ALARM_POINT') }} {{ listFilters.alarmPointId }}</span><br/>
          <span style="color: #82B541;">{{ _t('label_active') }} {{ listFilters.active }}</span><br/>
          <span style="color: #82B541;">
              {{ _t('label_instruction_files') }}
              {{ listFilters.withAttachments }}
            </span>
          <br/>
          <span style="color: #82B541;">
              {{ _t('label_alarm_plans') }}
              {{ listFilters.withAlarmPlans }}
            </span>
          <br/>
          <span style="color: #82B541;">
              {{ _t('label_confirm_mode') }}
              {{ listFilters.hasConfirmation }}
            </span>
          <br/>
          <span style="color: #82B541;">
              {{ _t('label_Follow_tab') }}
              {{ listFilters.hasFollowUp }}
            </span>
          <br/>
          <span style="color: #82B541;">
              {{ _t('REPETITION_TYPE') }}
              {{ listFilters.hasRepetition }}
            </span>
          <br/>
          <span style="color: #82B541;">
              {{ _t('menu.standby_schedules') }}
              {{ listFilters.hasStandbySchedule }}
            </span>
          <br/>
          <span style="color: #82B541;">
              {{ _t('label_rep_mon_interval') }}
              {{ listFilters.isLifesign }}
            </span>
          <br/>
          <span style="color: #82B541;">
              Priority:
              {{ listFilters.priority.low }} - {{ listFilters.priority.high }}
            </span>
          <br/>
          <span style="color: #82B541;">{{ _t('label_members') }}:</span><br/>
          <span v-for="member in listFilters.members" v-bind:key="member.id" style="color: #82B541;">- {{
              member.title
            }}<br/></span>
        </div>
        <div style="display: flex; flex-direction: row; align-items: center">
        <span :style="expandSidebarDialog ? 'width:100%; margin-left: 1rem;' : 'width:100%; margin-left: 5px;'"
              class="p-input-icon-right">
          <i class="pi pi-search" style="font-size: 12px;"/>
          <InputText v-show="!listFiltersEnabled" v-model="alarmPointSearch" style="width:100%;" type="text"/>
        </span>
          <Button
              v-tooltip="_t('label_advanced_filters')"
              :style="`position: relative; z-index: 1; float: right; margin: .6rem;`"
              class="p-button-rounded p-button-text p-button-secondary"
              icon="pi pi-filter"
              @click="filtersDialog = true"
          />
        </div>
      </div>
      <AlarmPointTable :alarmPointRows="alarmPointRows"
                       :alarmPointValues="alarmPointValues"
                       :alarmPoints="alarmPoints"
                       :apCurrentPage="apCurrentPage"
                       :apTotalRecords="apTotalRecords"
                       :expandSidebarDialog="expandSidebarDialog"
                       :getAlarmPlans="getAlarmPlans"
                       :getAlarmPoint="getAlarmPoint"
                       :getAlarmPoints="getAlarmPoints"
                       :loadInstructions="loadInstructions"
                       :loading="loading"
                       :selectTab="selectedTab"
                       :tableDataKey="tableDataKey"
                       :totalRecords="totalRecords"
                       @setApCurrentPage="setApCurrentPage"
      />
    </div>

    <AlarmPointItem :id="selectedAlarmPoint.id"
                    :item="selectedAlarmPoint"
                    @onClearSearchValue="alarmPointSearch = ''"
                    @callAlarmPoints="getAlarmPoints()"
                    @callApplyListFilters="applyListFilters"
    />

  </div>

  <Dialog v-model:visible="newAlarmPointDialog" :style="{width: '450px'}" modal>
    <template #header>
      <h3>{{ _t('menu.alarm_definition.new') }}</h3>
    </template>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-key"></i>
          </span>
          <InputText v-model="selectedAlarmPoint.alarmPointId" :placeholder="_t('label_alarm_id')"/>
        </div>
        <small v-if="validation.alarmPointId" class="p-error">{{ _t('id_required') }}</small>
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi pi-pencil"></i>
          </span>
          <InputText v-model="selectedAlarmPoint.name" :placeholder="_t('label_object_name')"/>
        </div>
        <small v-if="validation.name" class="p-error">{{ _t('label.name_required') }}</small>
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <OrganizationLink v-model="selectedAlarmPoint" :editMode="true" :required-field="true"/>
        </div>
      </div>
    </div>

    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="newAlarmPointDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="createAlarmPoint()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="filtersDialog" style="max-width: 40em;">
    <template #header>
      <h3>{{ _t('label_advanced_filters') }}</h3>
    </template>
    <div class="p-grid">
      <div class="p-col-3">
        <p>{{ _t('label_object_name') }}</p>
      </div>
      <div class="p-col-9">
        <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.name = ''"/>
        <InputText v-model="listFilters.name" :placeholder="_t('label_search_by_name')" style="width:27rem;"/>
        </span>
      </div>
      <div class="p-col-3">
        <p>{{ _t('label_system_id') }}</p>
      </div>
      <div class="p-col-9">
         <span class="p-input-icon-right">
           <i class="pi pi-times" @click="listFilters.systemId = ''"/>
           <InputText v-model="listFilters.systemId" :placeholder="_t('label_search_by_system_ID')"
                      style="width:27rem;"/>
        </span>
      </div>
      <div class="p-col-3">
        <p>Priority</p>
      </div>
      <div class="p-col-9">
         <span class="p-input-icon-right">
           <i class="pi pi-times" @click="listFilters.priority.low = ''"/>
           <InputText v-model="listFilters.priority.low" placeholder="From" style="width:27rem;"/>
        </span>
        <div class="p-pb-0"/>
        <span class="p-input-icon-right">
           <i class="pi pi-times" @click="listFilters.priority.high = ''"/>
           <InputText v-model="listFilters.priority.high" placeholder="To" style="width:27rem;"/>
        </span>

      </div>

      <div class="p-col-3">
        <p>{{ _t('label_alert_id') }}</p>
      </div>
      <div class="p-col-9">
         <span class="p-input-icon-right">
           <i class="pi pi-times" @click="listFilters.alarmPointId = ''"/>
           <InputText v-model="listFilters.alarmPointId" :placeholder="_t('label_search_by_alarm_point_ID')"
                      style="width:27rem;"/>
        </span>
      </div>
      <div class="p-col-3">
        <p>{{ _t('label_active') }}</p>
      </div>
      <div class="p-col-9">
        <div v-for="(option, i) in filterRadio" :key="option" class="p-field-radiobutton p-col"
             style="float: left; margin-bottom: 0;">
          <RadioButton :id="'filterActive'+i"
                       v-model="listFilters.active"
                       :value="option.value"
                       name="filterActive"
          />
          <label :for="'filterActive'+i">{{ option.label }}</label>
        </div>
      </div>
      <div class="p-col-3">
        <p>{{ _t('label_instruction_files') }}</p>
      </div>
      <div class="p-col-9">
        <div v-for="(option, i) in filterRadio" :key="option" class="p-field-radiobutton p-col"
             style="float: left; margin-bottom: 0;">
          <RadioButton :id="'filterAttachments'+i"
                       v-model="listFilters.withAttachments"
                       :value="option.value"
                       name="filterAttachments"
          />
          <label :for="'filterAttachments'+i">{{ option.label }}</label>
        </div>
      </div>
      <div class="p-col-3">
        <p>{{ _t('label_alarm_plans') }}</p>
      </div>
      <div class="p-col-9">
        <div v-for="(option, i) in filterRadio" :key="option" class="p-field-radiobutton p-col"
             style="float: left; margin-bottom: 0;">
          <RadioButton :id="'filterAttachments'+i"
                       v-model="listFilters.withAlarmPlans"
                       :value="option.value"
                       name="filterAttachments"
          />
          <label :for="'filterAttachments'+i">{{ option.label }}</label>
        </div>
      </div>
      <div class="p-col-3">
        <p>{{ _t('label_confirm_mode') }}</p>
      </div>
      <div class="p-col-9">
        <div v-for="(option, i) in filterRadio" :key="option" class="p-field-radiobutton p-col"
             style="float: left; margin-bottom: 0;">
          <RadioButton :id="'filterAttachments'+i"
                       v-model="listFilters.hasConfirmation"
                       :value="option.value"
                       name="filterAttachments"
          />
          <label :for="'filterAttachments'+i">{{ option.label }}</label>
        </div>
      </div>
      <div class="p-col-3">
        <p>{{ _t('label_Follow_tab') }}</p>
      </div>
      <div class="p-col-9">
        <div v-for="(option, i) in filterRadio" :key="option" class="p-field-radiobutton p-col"
             style="float: left; margin-bottom: 0;">
          <RadioButton :id="'filterAttachments'+i"
                       v-model="listFilters.hasFollowUp"
                       :value="option.value"
                       name="filterAttachments"
          />
          <label :for="'filterAttachments'+i">{{ option.label }}</label>
        </div>
      </div>
      <div class="p-col-3">
        <p>{{ _t('REPETITION_TYPE') }}</p>
      </div>
      <div class="p-col-9">
        <div v-for="(option, i) in filterRadio" :key="option" class="p-field-radiobutton p-col"
             style="float: left; margin-bottom: 0;">
          <RadioButton :id="'filterAttachments'+i"
                       v-model="listFilters.hasRepetition"
                       :value="option.value"
                       name="filterAttachments"
          />
          <label :for="'filterAttachments'+i">{{ option.label }}</label>
        </div>
      </div>
      <div class="p-col-3">
        <p>{{ _t('menu.standby_schedules') }}</p>
      </div>
      <div class="p-col-9">
        <div v-for="(option, i) in filterRadio" :key="option" class="p-field-radiobutton p-col"
             style="float: left; margin-bottom: 0;">
          <RadioButton :id="'filterAttachments'+i"
                       v-model="listFilters.hasStandbySchedule"
                       :value="option.value"
                       name="filterAttachments"
          />
          <label :for="'filterAttachments'+i">{{ option.label }}</label>
        </div>
      </div>
      <div class="p-col-3">
        <p>{{ _t('label_rep_mon_interval') }}</p>
      </div>
      <div class="p-col-9">
        <div v-for="(option, i) in filterRadio" :key="option" class="p-field-radiobutton p-col"
             style="float: left; margin-bottom: 0;">
          <RadioButton :id="'filterAttachments'+i"
                       v-model="listFilters.isLifesign"
                       :value="option.value"
                       name="filterAttachments"
          />
          <label :for="'filterAttachments'+i">{{ option.label }}</label>
        </div>
      </div>
      <div class="p-col-3">
        <p>{{ _t('PARTICIPANTS') }}</p>
      </div>
      <div class="p-col-9">
        <ParticipantSelector v-model="listFilters.members" scrollHeight="40vh" style="width:18vw;"/>
      </div>
    </div>
    <template #footer>
      <Button :label="_t('Clear')" class="p-button-text p-button-danger" icon="pi pi-times"
              @click="applyListFilters(true)"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" icon="pi pi-check" @click="applyListFilters()"/>
    </template>
  </Dialog>
</template>
<style scoped>
.hidden {
  display: none;
}

.color_sample {
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  margin: 0 auto;
}

.icon_big {
  font-size: 48px;
  padding: 4px;
  border-radius: 4px;
}

.icon_medium {
  font-size: 24px;
  padding: 4px;
  border-radius: 4px;
}

.icon {
  font-size: 24px;
}

/*:deep(.p-tabview-panels .p-tabview-nav-container) {*/
/*  display: none;*/
/*}*/

/*:deep(.p-tabview-panels .p-tabview-panels) {*/
/*  padding: 0;*/
/*}*/
</style>
<style>
/*.p-treetable .p-treetable-tbody > tr > td {*/
/*  width: calc(100% / 5);*/
/*}*/

.p-listbox.grp .p-listbox-list .p-listbox-item {
  padding-left: 2em;
}

.p-tree.tight .p-tree-filter-container {
  width: calc(100% - 30px);
}

.p-tree.tight .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: -0.5rem;
  width: 1.2rem;
  height: 1.7rem;
}

.p-tree.tight .p-treenode-label {
  max-width: 215px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-treetable-scrollable .p-treetable-tbody > tr {
  display: -webkit-box;
}
</style>
<script>
import {getList, getListExtended} from '@/api/list'
import {
  createAlarmPoint,
  getAlarmPoint,
  updateAlarmPoint
} from '@/api/alarmPoint'
import {createAlarmPlan, getAlarmPlan, updateAlarmPlan} from '@/api/alarmPlan'
import MultiSelectableBreadcrumb from '@/components/ixarma/MultiSelectableBreadcrumb'
import ParticipantSelector from '../../components/ixarma/ParticipantSelector'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {getInstructionFiles} from '@/api/attachments'
import moment from "moment-timezone";
import {getOrganizationColumns, getUserColumns} from "@/api/uiColumns";
import AlarmPointTable from '../../components/Table/alarm_point/AlarmPointTable'
import AlarmPointItem from './item'

export default {
  name: 'index',
  components: {
    MultiSelectableBreadcrumb,
    ParticipantSelector,
    AlarmPointTable,
    AlarmPointItem
  },
  data() {
    return {
      alarmPoints: [],
      alarmPointsOriginal: [],
      selectedAlarmPointItem: null,
      alarmPointRows: 100,
      tableDataKey: null,
      selectedAlarmPoint: {
        id: this.$route.params.id || null,
        icon: {
          name: '',
          color: ''
        }
      },
      isEditable: false,
      calendarSelectOption: '',
      alarmPointSearch: '',
      deleteAlarmPointDialog: false,
      newAlarmPointDialog: false,
      selectedTextVar: null,
      textVars: [
        '<original>', '<K>', '<G>', '<family>', '<callerid>'
      ],
      confirmationModes: [],
      mediaUsageTypes: [],
      mediaProcessingTypes: [],
      triggerings: [],
      srcOptions: [
        {value: 'EXIST', label: 'label_Selected_plan'},
        {value: 'NEW', label: 'label_Define_new_plan'},
        {value: 'SIMPLE', label: 'label_Simplified_entry'},
      ],
      instructions: [],
      srcOption: null,
      repeatableMonitored: false,
      activeTabSimple: 0,
      selectedAlarmPlan: {
        id: null
      },
      alarmPlans: [],
      selectedCallGroupMembers: {},
      callRules: [],
      callRulesSimple: [],
      selectRules: [],
      participants: [],
      participantGroups: [],
      newCallGroupDialog: false,
      newCallGroup: {},
      editCallGroupDialog: false,
      prefillTextTemplateDialog: false,
      originalCallGroup: null,
      editCallGroup: {
        participants: [],
        participantGroups: [],
      },
      deleteCallGroupDialog: false,
      deleteCallGroupIndex: -1,
      participantFilterValue: '',
      treeParams: '',
      orgId: localStorage.getItem('orgId') ?? null,
      iconColors: [
        '#3996f3',
        '#314c5d',
        '#fad165',
        '#85b44a',
        '#8c5cfd',
        '#46c1d3',
        '#e23e57',
        '#989898',
        '#1b499e',
        '#f5856d'
      ],
      featuredIcons: [
        'mdi mdi-alert-outline',
        'mdi mdi-alert-box-outline',
        'mdi mdi-exit-to-app',
        'mdi mdi-fire',
        'mdi mdi-water-alert-outline',
        'mdi mdi-hospital-box-outline',
        'mdi mdi-wrench-outline',
        'mdi mdi-lan-disconnect',
        'mdi mdi-alarm-light-outline',
        'mdi mdi-shield-alert-outline',
        'mdi mdi-account-alert-outline',
        'mdi mdi-lock-open-outline',
        'mdi mdi-help-circle-outline',
        'mdi mdi-information-outline'
      ],
      icons: [],
      alarmPointsGrp: [],
      regularCalendar: {
        events: [{
          "resourceId": "d",
          "title": "event 1",
          "start": "2021-09-23T12:30:00+00:00",
          "end": "2021-09-25T15:30:00+00:00"
        }, {
          "resourceId": "c",
          "title": "event 3",
          "start": "2021-09-24T12:30:00+00:00",
          "end": "2021-09-25T06:45:00+00:00"
        }, {
          "resourceId": "f",
          "title": "event 4",
          "start": "2021-09-24T07:30:00+00:00",
          "end": "2021-09-24T09:30:00+00:00"
        }, {
          "resourceId": "b",
          "title": "event 5",
          "start": "2021-09-24T10:00:00+00:00",
          "end": "2021-09-24T15:00:00+00:00"
        }, {
          "resourceId": "e",
          "title": "event 2",
          "start": "2021-09-24T09:00:00+00:00",
          "end": "2021-09-24T14:00:00+00:00"
        }],
      },
      activeTab: 0,
      rotationalCalendar: '',
      newEventDialog: false,
      allDay: false,
      repeatByMonth: [{
        label: 'Day of month',
        value: 'DAY_OF_THE_MONTH',
      }, {
        label: 'Day of week',
        value: 'DAY_OF_THE_WEEK',
      }],
      filteredAlarmPoints: null,
      hoursDuration: 999999999,
      groupFilter: false,
      selectedTab: 'NAME',
      tabs: [
        {label: 'label_Alarm_name', value: 'NAME'},
        {label: 'label_alarm_id', value: 'ALARMID'},
        {label: 'label_system_id', value: 'SYSTEMID'},
      ],
      selectorIsLoading: false,
      filterRadio: [],
      filtersDialog: false,
      expandSidebarDialog: false,
      listFilters: {
        members: [],
        priority: {
          high: 10,
          low: 0
        },
        withAlarmPlans: 'ANY'
      },
      listFiltersEnabled: false,
      alarmRules: [],
      filterWithAlarmPlans: [],
      validation: {
        name: false,
        alarmPointId: false,
        confirmPriority: 0,
        text: false
      },
      loadedAlarmPointsPage: 0,
      loadedAlarmPointsCount: 100,
      isDeleted: false,
      currentPage: 0,
      apCurrentPage: 0,
      totalRecords: 0,
      apTotalRecords: 0,
      childFilter: true,
      alarmPointValues: [],
      userGroups: [],
      userGroupPage: 0,
      lazyLoadUGRecords: true,
      calledWarning: false,
      loading: false,
    }
  },
  watch: {
    selectedTab() {
      if (!this.selectedTab) {
        this.alarmPointSearch = ''
      }
    },
    rotationalCalendar(value) {
      if (typeof value === 'string') {
        this.callRotationalCalendars({'filterValue': value, 'tableRows': 100});
      }
    },
    calendarSelectOption(value) {
      if (value !== 'assign') {
        this.rotationalCalendar = {
          id: null,
          name: '',
          description: '',
          validFrom: moment().format('DD.MM.YYYY'),
        }
      }
    },
    childFilter() {
      this.getAlarmPoints()
    },
    alarmPointSearch(value) {
      if (value.length > 2 || value.length === 0) {
        this.getAlarmPoints()
      }
    },
    isEditable(value) {
      try {
        this.setRotationalIsEditable(value);
        this.setIndividualIsEditable(value);
      } catch (e) {
        console.log('calendar error')
      }
    },
    allDay: function () {
      if (this.allDay) {
        this.getEvent.startTime = '00:00';
        this.getEvent.endTime = '23:59';
      }
    },
    activeTab(value) {
      this.calendarSelectOption = ''

      this.$router.push({
        query: {
          active_tab: value
        }
      });
    },
    '$route.query.active_tab'(value) {
      if (value >= 0) {
        this.activeTab = parseInt(value);
      }
    },
    '$route.params.id'(value) {
      if (value !== '') {
        this.getAlarmPoint(value)
        this.loadInstructions()
      }
      this.calledWarning = false

      if(!value) {
        this.selectedAlarmPoint.id = null;
      }
    },
    '$route.name'(value) {
      if (value === 'deleted-alarm-points-list') {
        this.isDeleted = true
        this.getAlarmPoints()
      } else {
        this.alarmPointSearch = ''
        this.isDeleted = false
        this.getAlarmPoints()
      }

      if(value !== 'alarm-points') {
        this.selectedAlarmPoint.id = null
      }
    },
    '$refs.groupPG'(value) {
      console.log(value)
    }
  },
  beforeUnmount() {
  },
  mounted() {
    this.mediaProcessingTypes = [
      {value: 'CONFERENCE_CALL', label: this._t('CONFERENCE_CALL')},
      {value: 'BY_RANK', label: this._t('BY_RANK')}
    ]
    this.triggerings = [
      {value: 'WHEN_ON', label: this._t('WHEN_ON')},
      {value: 'WHEN_ON_AND_OFF', label: this._t('WHEN_ON_AND_OFF')}
    ]
    this.mediaUsageTypes = [
      {value: 'ALL', label: this._t('label_as_configured')},
      {value: 'VOICE', label: this._t('label_voice_only')},
      {value: 'TEXT', label: this._t('label_text_only')}
    ]
    this.confirmationModes = [
      {value: 'NONE', label: this._t('_NONE')},
      {value: 'MEDIA', label: this._t('_MEDIA')},
      {value: 'PARTICIPANT', label: this._t('_PARTICIPANT')},
      {value: 'MEDIA_AND_PARTICIPANT', label: this._t('_MEDIA_AND_PARTICIPANT')}
    ]
    this.icons = [
      {
        label: this._t('menu.general'),
        icons: [
          'mdi mdi-alert-outline',
          'mdi mdi-bell-ring-outline',
          'mdi mdi-alarm',
          'mdi mdi-alert-rhombus-outline',
          'mdi mdi-bell-alert-outline',
          'mdi mdi-car-brake-alert',
          'mdi mdi-alert-circle-outline',
          'mdi mdi-alert-octagon-outline',
          'mdi mdi-alert-decagram-outline',
          'mdi mdi-alert-box-outline',
          'mdi mdi-weather-cloudy-alert',
          'mdi mdi-alarm-multiple',
          'mdi mdi-bell-circle-outline',
        ]
      },
      {
        label: this._t('label_Alarms'),
        icons: [
          'mdi mdi-exit-run',
          'mdi mdi-exit-to-app',
          'mdi mdi-fire',
          'mdi mdi-fire-extinguisher',
          'mdi mdi-air-horn',
          'mdi mdi-fire-hydrant',
          'mdi mdi-water-alert-outline',
          'mdi mdi-water-remove-outline',
          'mdi mdi-pipe-leak',
          'mdi mdi-ambulance',
          'mdi mdi-medical-bag',
          'mdi mdi-hospital-box-outline',
          'mdi mdi-zodiac-aquarius',
          'mdi mdi-wrench-outline',
          'mdi mdi-server-off',
          'mdi mdi-lan-disconnect',
          'mdi mdi-alarm-light-outline',
        ]
      },
      {
        label: this._t('label_Danger'),
        icons: [
          'mdi mdi-alarm-light-outline',
          'mdi mdi-hazard-lights',
          'mdi mdi-triforce',
          'mdi mdi-molecule-co2',
          'mdi mdi-flash-alert-outline',
          'mdi mdi-bottle-tonic-skull-outline',
          'mdi mdi-skull-crossbones-outline',
          'mdi mdi-skull',
          'mdi mdi-virus-outline',
          'mdi mdi-bacteria-outline',


        ]
      },
      {
        label: this._t('label_Security'),
        icons: [
          'mdi mdi-shield-alert-outline',
          'mdi mdi-shield-key-outline',
          'mdi mdi-account-alert-outline',
          'mdi mdi-security-network',
          'mdi mdi-security',
          'mdi mdi-lock-open-alert',
          'mdi mdi-lock-open-outline',
          'mdi mdi-key-outline',
          'mdi mdi-key-remove',
          'mdi mdi-home-alert',
          'mdi mdi-home-lock-open',
          'mdi mdi-window-shutter-alert',
          'mdi mdi-volume-off',
        ]
      },
      {
        label: this._t('label_Maintenance'),
        icons: [
          'mdi mdi-broom',
          'mdi mdi-rake',
          'mdi mdi-hammer-wrench',
          'mdi mdi-wrench-outline',
        ]
      },
      {
        label: this._t('label_IT'),
        icons: [
          'mdi mdi-help-circle-outline',
          'mdi mdi-lan-disconnect',
          'mdi mdi-server-off',
          'mdi mdi-desktop-classic',
          'mdi mdi-content-save-alert-outline',
          'mdi mdi-disc-alert',
          'mdi mdi-timeline-alert-outline',
          'mdi mdi-folder-key-outline',
          'mdi mdi-folder-alert',
          'mdi mdi-table-alert',
          'mdi mdi-wifi-strength-alert-outline',
          'mdi mdi-restart-alert',

        ]
      },
      {
        label: this._t('label_Info_Calendar'),
        icons: [
          'mdi mdi-information-outline',
          'mdi mdi-information-variant',
          'mdi mdi-clock-alert-outline',
          'mdi mdi-calendar-alert',
          'mdi mdi-exclamation',
          'mdi mdi-exclamation-thick',
          'mdi mdi-clipboard-alert-outline',
          'mdi mdi-sticker-alert-outline',
          'mdi mdi-coffee-outline',
        ]
      },
      {
        label: this._t('label_Traffic'),
        icons: [
          'mdi mdi-bus-alert',
          'mdi mdi-subway-alert-variant',
          'mdi mdi-traffic-light',
        ]
      },
      {
        label: this._t('label_Technical'),
        icons: [
          'mdi mdi-coolant-temperature',
          'mdi mdi-radioactive',
          'mdi mdi-printer-3d-nozzle-alert-outline',
          'mdi mdi-tray-alert',
          'mdi mdi-beaker-alert-outline',
          'mdi mdi-water-percent-alert',
          'mdi mdi-thermometer-alert',
          'mdi mdi-thermometer-lines',
          'mdi mdi-oil-level',
          'mdi mdi-dishwasher-alert',
          'mdi mdi-battery-alert-variant-outline',
          'mdi mdi-vibrate',
          'mdi mdi-watch-vibrate',
          'mdi mdi-fuse-alert',
          'mdi mdi-engine-outline',
          'mdi mdi-fridge-alert-outline',
          'mdi mdi-state-machine',
          'mdi mdi-gas-cylinder',
          'mdi mdi-diving-scuba-tank',
          'mdi mdi-fan-alert',
          'mdi mdi-lightbulb-on-outline',
          'mdi mdi-power-plug-off-outline',
          'mdi mdi-car-tire-alert',
          'mdi mdi-lightning-bolt-outline',
          'mdi mdi-transmission-tower',
          'mdi mdi-scale-balance',
        ]
      },
      {
        label: this._t('label_Weather'),
        icons: [
          'mdi mdi-snowflake-alert',
          'mdi mdi-snowflake-melt',
          'mdi mdi-weather-cloudy-alert',
          'mdi mdi-weather-lightning',
          'mdi mdi-weather-pouring',
        ]
      },
      {
        label: this._t('label_media'),
        icons: [
          'mdi mdi-book-open-variant',
          'mdi mdi-cellphone-basic',
          'mdi mdi-cellphone',
          'mdi mdi-monitor',
          'mdi mdi-alarm-light-outline',
          'mdi mdi-phone-in-talk',
          'mdi mdi-email',
          'mdi mdi-card-text-outline',
          'mdi mdi-cellphone-text',
          'mdi mdi-message-text',
          'mdi mdi-cellphone-wireless',
          'mdi mdi-message-video',
          'mdi mdi-application-import',
          'mdi mdi-database-import',
          'mdi mdi-phone-voip',
          'mdi mdi-application-export',
          'mdi mdi-printer',
          'mdi mdi-database-export',
          'mdi mdi-motion-sensor',
          'mdi mdi-switch',
          'mdi mdi-serial-port',
          'mdi mdi-lighthouse',
          'mdi mdi-lighthouse',
          'mdi mdi-led-on',
          'mdi mdi-cup-water',
          'mdi mdi-gesture-tap-hold',
        ]
      }
    ]
    this.filterRadio = [
      {value: 'ANY', label: this._t('label_any')},
      {value: 'YES', label: this._t('label_yes')},
      {value: 'NO', label: this._t('label_no')},
    ]
    this.callRules = [
      {value: 'ALL', label: this._t('ALL')},
      {value: 'PRECISELY', label: this._t('PRECISELY')},
      {value: 'MINIMUM', label: this._t('MINIMUM')}
    ]
    this.selectRules = [
      {value: 'NONE', label: this._t('_NONE')},
      {value: 'RANDOM', label: this._t('label_random')},
      {value: 'ESCALATION', label: this._t('label_escalation')}
    ]
    this.callRulesSimple = [
      {value: 'A', label: this._t('label_Plan_Option1_text')},
      {
        value: 'B',
        label: this._t('label_Plan_Option2_text')
      },
      {
        value: 'C',
        label: this._t('label_Plan_Option3_text')
      },
      {value: 'OTHER', label: 'Simple view configuration is not applicable'}
    ]
    this.listFilters = {
      name: '',
      systemId: '',
      alarmPointId: '',
      active: 'ANY',
      withAttachments: 'ANY',
      withAlarmPlans: 'ANY',
      hasConfirmation: "ANY",
      hasFollowUp: "ANY",
      hasRepetition: "ANY",
      hasStandbySchedule: "ANY",
      isLifesign: "ANY",
      members: [],
      priority: {
        high: 10,
        low: 0,
      },
    }
    this.filterWithAlarmPlans = [
      {value: 'ANY', label: this._t('label_any')},
      {value: 'YES', label: this._t('label_yes')},
      {value: 'NO', label: this._t('label_no')},
    ]
    this.selectedAlarmPoint = {
      id: null,
      icon: {
        name: '',
        color: ''
      }
    }
    if (this.$route.name === 'deleted-alarm-points-list') {
      this.isDeleted = true
      this.getAlarmPoints()
    } else {
      this.alarmPointSearch = ''
      this.isDeleted = false
      this.getAlarmPoints()
    }
    this.getAlarmPlans()
    this.getAlarmRules()
    if (this.$route.params.id) {
      this.getAlarmPoint(this.$route.params.id)
      this.loadInstructions()
    }

    if (this.$route.query.active_tab) {
      this.activeTab = parseInt(this.$route.query.active_tab)
    }

    this.checkOrgId()
    this.callRotationalCalendars({orgId: this.orgId, tableRows: 99999})

    getUserColumns().then((userRes) => {
      if (userRes.data) {
        for (const [key, value] of Object.entries(userRes.data)) {
          if (key === 'alarmPointItems') this.alarmPointValues = value
        }
      } else {
        getOrganizationColumns().then((res) => {
          if (res.data) {
            for (const [key, value] of Object.entries(res.data)) {
              if (key === 'alarmPointItems') this.alarmPointValues = value
            }
          }
        }).catch((err) => console.log('error', err))
      }
    }).catch((err) => console.log('error', err))
    this.getUserGroups()

  },
  methods: {
    ...mapActions({
      'callCalendarById': 'rotationalCalendar/callCalendarById',
      'createRotaionalCalendar': 'rotationalCalendar/callCreateCalendar',
      'updateRotaionalCalendar': 'rotationalCalendar/callUpdateCalendar',
      'callRotationalCalendars': 'rotationalCalendar/callCalendars',
      'callAlarmPointCalendar': 'alarmpoints/callCalendar',
      'callCreateUpdateEvent': 'alarmpoints/callCreateUpdateEvent',
      'callRemoveEvent': 'alarmpoints/callRemoveEvent',
    }),
    ...mapMutations({
      'setCalendar': 'alarmpoints/setCalendar',
      'setEvents': 'alarmpoints/setEvents',
      'setEvent': 'alarmpoints/setEvent',
      'setRotationalIsEditable': 'rotationalCalendar/setIsEditable',
      'setRotationalCalendar': 'rotationalCalendar/setCalendar',
      'setIndividualIsEditable': 'alarmpoints/setIsEditable',
      'createEmptyEvent': 'alarmpoints/createEmptyEvent',
      'setIsEditable': 'alarmpoints/setIsEditable',
      'selectEvent': 'alarmpoints/selectEvent',
      'createEmptyCalendar': 'rotationalCalendar/createEmptyCalendar',
    }),
    setApCurrentPage(val) {
      this.apCurrentPage = val
    },
    getUserGroups() {
      if (this.lazyLoadUGRecords) {
        getList('USERGROUP', this.userGroupPage, '', 50, this.orgId).then((response) => {
          if (response.data.list.length === 0) {
            this.lazyLoadUGRecords = false
          }
          this.userGroups = [...this.userGroups, ...response.data.list]
          this.userGroupPage++

        })
      }
    },
    checkOrgId() {
      this.checkOrgIdIntervalId = setInterval(() => {
        if (this.orgId !== localStorage.getItem('orgId')) {
          this.orgId = localStorage.getItem('orgId')
          this.applyListFilters(true)
          this.getAlarmPoints()
        }
      }, 100);
    },
    setOrgId(id) {
      this.orgId = id;
      this.applyListFilters(true)
      this.getAlarmPoints()
    },
    getAlarmPoints() {
      this.alarmPointsGrp = []
      this.alarmPoints = []
      if (this.listFiltersEnabled) {
        this.applyListFilters();
      } else {
        getList('ALARMPOINT', this.apCurrentPage, this.alarmPointSearch, this.alarmPointRows, this.orgId, this.childFilter, this.computedTab, this.isDeleted).then((response) => {
          this.alarmPoints = response.data.list.filter(function (item) {
            return !item.name.includes('mob_app');
          })

          this.apTotalRecords = response.data.count
          this.alarmPointsOriginal = response.data.list
          this.loading = false;
        })
      }
    },
    getAlarmRules() {
      getList('ALARM_RULE', 0, this.apFilterValue, 1000).then((response) => {
        this.alarmRules = response.data.list
        this.alarmRules.sort((a, b) => a.name.localeCompare(b.name))
        this.currentPage = response.data.currentPage
        this.totalRecords = response.data.count
        this.loading = false
      })
    },
    selectTablePage(item) {
      this.loading = true
      this.apCurrentPage = item.page
      this.getAlarmPoints();
    },
    getAlarmPoint(id, setIsEditable = false) {
      if (id) {
        getAlarmPoint(id).then((response) => {
          this.isEditable = setIsEditable
          this.selectedAlarmPoint = response.data
          if (this.selectedAlarmPoint.id && this.selectedAlarmPoint.id !== 'null') {
            this.isDeleted = this.selectedAlarmPoint.deleted
            if (this.$refs.reportHistory) {
              this.$refs.reportHistory.getHistoryByAlarmId(
                  this.selectedAlarmPoint.id,
                  {
                    alarmName: null,
                    alarmPointId: this.selectedAlarmPoint.alarmPointId,
                    statuses: [],
                    systemId: null,
                    triggeredBy: null,
                  },
                  {
                    hours: this.hoursDuration,
                    option: 'HOURS_DURATION'
                  }
              )
            }
          }

          if (this.selectedAlarmPoint.repeatMonInterval && this.selectedAlarmPoint.repeatMonInterval !== 0) {
            this.repeatableMonitored = true
          } else {
            this.repeatableMonitored = false
          }

          this.getAlarmPlan(this.selectedAlarmPoint.alarmPlanId)

          this.callCalendarById(this.selectedAlarmPoint.rotationalCalendarId)
              .then(() => {
                if (this.getCalendar && !this.calledWarning) {
                  this.$toast.add({
                    severity: 'info',
                    summary: this._t('ALARM_POINT_ROTATIONAL_CAL'),
                    detail: this._t('label_plan_replaced_by_schedule'),
                    life: 5000,
                    closable: true
                  });
                  this.calledWarning = true
                }
              })

          this.callAlarmPointCalendar(this.selectedAlarmPoint.id)
          this.rotationalCalendar = '';
          try {
            this.selectedAlarmPoint.icon = JSON.parse(this.selectedAlarmPoint.icon)
          } finally {
            if (!this.selectedAlarmPoint.icon || typeof this.selectedAlarmPoint.icon !== 'object') {
              this.selectedAlarmPoint.icon = {
                color: '#989898',
                name: 'mdi mdi-alert-outline'
              }
            }
          }
          this.srcOption = 'EXIST'
          // this.activeTab = 0
          this.$forceUpdate()
          this.loading = false
        })
      }
    },
    loadInstructions() {
      getInstructionFiles('')
          .then(res => {
            if (res) {
              this.instructions = this.getInstructionsRecursive(res.data.instructions)
              if (this.instructions.length) {
                this.instructions.sort((a, b) =>
                    a.org.fullOrgPath.localeCompare(b.org.fullOrgPath)
                    || a.name.localeCompare(b.name)
                )
              }
            }
          })
    },
    getInstructionsRecursive(instructions, collection = [], org = {}) {
      for (const instruction of instructions) {
        if (instruction.files && instruction.files.length) {
          collection.concat(this.getInstructionsRecursive(instruction.files, collection, instruction.org))
        } else {
          collection.push({...instruction, org})
        }
      }
      return collection
    },
    initNewAlarmPointDialog() {
      this.selectedAlarmPoint = {
        id: null,
        version: 0,
        orgId: this.orgId ?? localStorage.getItem('orgId'),
        orgName: '',
        text: '',
        description: null,
        mediaUsageType: 'ALL',
        acknowledgeMode: 'NONE',
        confirmPriority: 1,
        mediaProcessingType: 'BY_RANK',
        instructionFile: null,
        speechText: null,
        localAcknowledgeTime: 0,
        countRepeat: 0,
        repeatBetween: 0,
        systemId: null,
        active: true,
        triggering: 'WHEN_ON',
        name: null,
        repeatMonInterval: 0,
        individualCalendarId: null,
        rotationalCalendarId: null,
        alarmPlanId: null,
        alarmRuleId: null,
        consequentialAlarms: [],
        alarmPointId: "",
        icon: {
          color: '#989898',
          name: 'mdi mdi-alert-outline'
        }
      }
      this.setRotationalCalendar(null)
      this.newAlarmPointDialog = true
    },
    validateAlarmPoint(isNew = false) {
      if (!this.selectedAlarmPoint.alarmPointId || this.selectedAlarmPoint.alarmPointId.length === 0) {
        this.validation.alarmPointId = true
        return false;
      } else {
        this.validation.alarmPointId = false
      }
      if (!this.selectedAlarmPoint.name || this.selectedAlarmPoint.name.length === 0) {
        this.validation.name = true
        return false;
      } else {
        this.validation.name = false
      }
      if (!isNew) {
        if (!this.selectedAlarmPoint.confirmPriority || this.selectedAlarmPoint.confirmPriority.length === 0) {
          this.validation.confirmPriority = 1
          return false;
        } else {
          this.validation.confirmPriority = 0
        }
        if (!this.selectedAlarmPoint.text || this.selectedAlarmPoint.text.length === 0) {
          this.validation.text = true
          return false;
        } else {
          this.validation.text = false
        }
      }
    },
    createAlarmPoint() {
      if (this.validateAlarmPoint(true) === false) {
        return false
      }
      if (!this.selectedAlarmPoint.orgId || !this.selectedAlarmPoint.alarmPointId || !this.selectedAlarmPoint.name) {
        return false;
      }

      this.selectedAlarmPoint.id = 'new'
      this.selectedAlarmPlan = {
        id: null
      }
      this.rotationalCalendar = ''
      this.newAlarmPointDialog = false
      this.isEditable = true
    },
    initCallGroupEditDialog(callGroup) {
      this.originalCallGroup = callGroup
      this.editCallGroup.members = Array.from(this.originalCallGroup.members)
      this.editCallGroupDialog = true
    },
    initCallGroupDeleteDialog(index) {
      this.deleteCallGroupIndex = index
      this.deleteCallGroupDialog = true
    },
    deleteCallGroup() {
      this.deleteCallGroupDialog = false
      this.selectedAlarmPlan.callGroups.splice(this.deleteCallGroupIndex, 1)
      this.deleteCallGroupIndex = -1
    },
    reorderCallGroupMembers(callGroup, ordered) {
      callGroup.members = ordered
      callGroup.members.forEach(function (member, index) {
        member.orderId = index
      })
    },
    updateCallGroupMembers() {
      this.originalCallGroup.members = Array.from(this.editCallGroup.members)
      this.editCallGroupDialog = false
      if (this.editCallGroup.simple) {
        this.syncGroupSelectRuleSimple(this.originalCallGroup)
      }
    },
    removeCallGroupMembers(callGroup, simple) {
      let selected = this.selectedCallGroupMembers[callGroup.id].filter(() => true);
      this.selectedCallGroupMembers[callGroup.id] = [];
      for (const value of selected) {
        if (value.participantId) {
          callGroup.members.splice(callGroup.members.findIndex(o => o.participantId == value.participantId), 1)
        } else if (value.participantGroupId) {
          callGroup.members.splice(callGroup.members.findIndex(o => o.participantGroupId == value.participantGroupId), 1)
        }
      }
      if (simple) {
        this.syncGroupSelectRuleSimple(callGroup)
      }
    },
    syncGroupSelectRuleSimple(callGroup) {
      if (callGroup.memberCallRuleSimple == 'OTHER') {
        this.activeTabSimple = 0
        this.syncGroupMemberCallRule(callGroup)
        return
      }
      if (this.hasParticipantGroups(callGroup)) {
        switch (callGroup.memberCallRuleSimple) {
          case 'A':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = false
            callGroup.isEscalation = true
            callGroup.memberSelectRule = 'ESCALATION'
            break;
          case 'B':
            callGroup.memberCallRule = 'MINIMUM'
            callGroup.isRandom = false
            callGroup.isEscalation = true
            callGroup.memberSelectRule = 'ESCALATION'
            break;
          case 'C':
            callGroup.memberCallRule = 'MINIMUM'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
        }
      } else {
        switch (callGroup.memberCallRuleSimple) {
          case 'A':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
          case 'B':
            callGroup.memberCallRule = 'MINIMUM'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
          case 'C':
            callGroup.memberCallRule = 'PRECISELY'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
        }
      }
    },
    syncGroupSelectRule(callGroup) {
      if (callGroup.memberSelectRule == 'RANDOM') {
        callGroup.isRandom = true
        callGroup.isEscalation = false
      } else if (callGroup.memberSelectRule == 'ESCALATION') {
        callGroup.isRandom = false
        callGroup.isEscalation = true
      }
      this.syncGroupMemberCallRule(callGroup)
    },
    syncGroupMemberCallRule(callGroup) {
      if (this.hasParticipantGroups(callGroup)) {
        if (callGroup.memberCallRule == 'ALL' && callGroup.isRandom == false && callGroup.isEscalation == true) {
          callGroup.memberCallRuleSimple = 'A'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == false && callGroup.isEscalation == true) {
          callGroup.memberCallRuleSimple = 'B'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'C'
        } else {
          callGroup.memberCallRuleSimple = 'OTHER'
        }
      } else {
        if (callGroup.memberCallRule == 'ALL' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'A'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'B'
        } else if (callGroup.memberCallRule == 'PRECISELY' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'C'
        } else {
          callGroup.memberCallRuleSimple = 'OTHER'
        }
      }
    },
    getAlarmPlan(id) {
      if (id) {
        getAlarmPlan(id).then((response) => {
          this.selectedAlarmPlan = response.data
          this.selectedAlarmPlan.callGroups.forEach(function (callGroup) {
            if (callGroup.isRandom == true && callGroup.isEscalation == false) {
              callGroup.memberSelectRule = 'RANDOM'
            } else if (callGroup.isRandom == false && callGroup.isEscalation == true) {
              callGroup.memberSelectRule = 'ESCALATION'
            } else {
              callGroup.memberSelectRule = 'NONE'
            }
            this.syncGroupMemberCallRule(callGroup)
          }, this)
        })

      } else {
        this.selectedAlarmPlan = {id: null}
      }
    },
    getAlarmPlans() {
      getList('ALARMPLAN', 0, this.apFilterValue, 1000).then((response) => {
        this.alarmPlans = response.data.list
        this.alarmPlans.sort((a, b) => a.name.localeCompare(b.name))
        this.currentPage = response.data.currentPage
        this.totalRecords = response.data.count
        this.loading = false
      })
    },
    saveAlarmPlan(setIsEditable = false) {
      let data = Object.assign({}, this.selectedAlarmPlan)
      if (data !== null && data.callGroups && data.callGroups.length > 0) {
        data.callGroups.forEach(function (group) {
          if (group.id && group.id.startsWith('_')) {
            delete group.id
          }
          if (group.alarmPlanId == null) {
            delete group.alarmPlanId
          }

          delete group.memberSelectRule
          delete group.memberCallRuleSimple

          group.members.forEach(function (member) {
            if (member.id && member.id.startsWith('_')) {
              delete member.id
            }
          })
        })

        if (data.id != null && !data.id.startsWith('_')) {
          updateAlarmPlan(data).then((response) => {
            this.getAlarmPlan(response.data.id)

            this.saveAlarmPoint(setIsEditable)
          }).catch((error) => {
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
          })
        } else {
          delete data.id
          createAlarmPlan(data).then((response) => {
            this.getAlarmPlan(response.data.id)
            this.selectedAlarmPoint.alarmPlanId = response.data.id

            this.saveAlarmPoint(setIsEditable)
            this.getAlarmPlans()
          }).catch((error) => {
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
          })
        }
        this.activeTabSimple = 0
        this.isEditable = setIsEditable
      }

    },
    saveAlarmPoint(setIsEditable = false) {
      // this.validateAlarmPoint()
      this.selectedAlarmPoint.icon = JSON.stringify(this.selectedAlarmPoint.icon)
      if (!this.selectedAlarmPoint.id || this.selectedAlarmPoint.id == null || this.selectedAlarmPoint.id.startsWith('_')) {
        delete this.selectedAlarmPoint.id

        createAlarmPoint(this.selectedAlarmPoint).then((response) => {
          this.getAlarmPoint(response.data.id, setIsEditable)
          this.isEditable = setIsEditable
          this.applyListFilters(true)
          this.getAlarmPoints()
        }).catch((error) => {
          let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
          this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
        })
      } else {
        this.$refs.orgLink.moveToOrg((increaseVersion) => {
          if (increaseVersion) this.selectedAlarmPoint.version++
          this.selectedAlarmPoint.consequentialAlarms = this.getTriggers
          updateAlarmPoint(this.selectedAlarmPoint).then((response) => {
            this.getAlarmPoint(response.data.id)
            this.isEditable = setIsEditable
            this.applyListFilters(true)
            this.getAlarmPoints()
          }).catch((error) => {
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
          })
        })
      }
    },
    createCallGroup() {
      this.selectedAlarmPlan.callGroups = Array.isArray(this.selectedAlarmPlan.callGroups) ? this.selectedAlarmPlan.callGroups : []

      this.newCallGroup.id = '_' + this.selectedAlarmPlan.callGroups.length
      this.newCallGroup.orgId = this.selectedAlarmPlan.orgId
      this.newCallGroup.orgName = this.selectedAlarmPlan.orgName
      this.newCallGroup.alarmPlanId = this.selectedAlarmPlan.id
      this.newCallGroup.atLeastCount = 0
      this.newCallGroup.memberCallRule = 'ALL'
      this.newCallGroup.isEscalation = false
      this.newCallGroup.isRandom = true
      this.newCallGroup.memberSelectRule = 'RANDOM'
      this.newCallGroup.memberCallRuleSimple = 'C'
      this.newCallGroup.members = []
      this.newCallGroup.version = 0
      this.newCallGroup.orderId = this.selectedAlarmPlan.callGroups ? this.selectedAlarmPlan.callGroups.length : 0

      let callGroup = Object.assign({}, this.newCallGroup)
      this.selectedAlarmPlan.callGroups.push(callGroup)
      this.newCallGroupDialog = false
      this.newCallGroup = {}
    },
    hasParticipantGroups(callGroup) {
      return callGroup.members.findIndex(o => o.participantGroupId != null) != -1
    },
    createAlarmPlan(simple = false) {
      this.selectedAlarmPlan = {
        id: null,
        name: simple ? 'Participant plan for ' + this.selectedAlarmPoint.alarmPointId : '',
        description: simple ? 'Automatically generated participant plan for ' + this.selectedAlarmPoint.name : '',
        orgId: this.selectedAlarmPoint.orgId,
        orgName: this.selectedAlarmPoint.orgName,
        callGroups: []
      }

      this.newCallGroup.name = this._t('label_default')
      this.createCallGroup()
    },
    clickEvent(event) {
      const eventId = event.target.getAttribute('data-id');
      if (eventId) {
        this.setIsEditable(true);
        this.selectEvent(eventId);
        this.newEventDialog = true;
      }
    },
    applyListFilters(reset = false) {
      this.alarmPoints = []
      if (reset === true || this.isListFiltersInitState()) {
        this.listFilters = {
          name: '',
          systemId: '',
          alarmPointId: '',
          active: 'ANY',
          withAttachments: 'ANY',
          withAlarmPlans: 'ANY',
          hasConfirmation: "ANY",
          hasFollowUp: "ANY",
          hasRepetition: "ANY",
          hasStandbySchedule: "ANY",
          isLifesign: "ANY",
          members: [],
          priority: {
            high: 10,
            low: 0
          },
        }
        this.listFiltersEnabled = false
        this.getAlarmPoints()
      } else {
        this.listFilters.active !== 'ANY' ? this.listFilters.isInactive = this.listFilters.active === 'NO' : delete this.listFilters.isInactive
        this.listFilters.deleted = this.isDeleted


        this.listFilters.members.map((element) => {
          let container = element;
          container.id = element.participantId
          return container;
        })
        getListExtended({"alarmPointMultiFilter": this.listFilters}, 'ALARMPOINT', this.apCurrentPage, this.alarmPointSearch, this.alarmPointRows, this.orgId, this.childFilter, this.computedTab, this.isDeleted).then((response) => {
          this.alarmPointsOriginal = response.data.list
          this.alarmPoints = response.data.list;
          this.apTotalRecords = response.data.count
          this.loading = false
          this.listFiltersEnabled = true
        })
      }
      this.filtersDialog = false
    },
    isListFiltersInitState() {
      return this.listFilters && this.listFilters.name === '' && this.listFilters.alarmPointId === '' &&
          this.listFilters.systemId === '' && this.listFilters.active === 'ANY' &&
          this.listFilters.withAttachments === 'ANY' && this.listFilters.withAlarmPlans === 'ANY' &&
          this.members === []
    },
  },
  computed: {
    ...mapGetters({
      'getRotationalCalendars': 'rotationalCalendar/getCalendars',
      'getCalendar': 'rotationalCalendar/getCalendar',
      'getAlarmPointCalendar': 'alarmpoints/getCalendar',
      'getEvents': 'alarmpoints/getEvents',
      'getEvent': 'alarmpoints/getEvent',
      'getIsEditable': 'alarmpoints/getIsEditable',
      'getRepeatTypes': 'alarmpoints/getRepeatTypes',
      'getMode': 'consequentials/getMode',
      'getTriggers': 'consequentials/getTriggers',
    }),
    computedTab: function () {
      switch (this.selectedTab) {
        case 'ALARMID':
          return 'alarmPointId';
        case 'SYSTEMID':
          return 'systemId';
        default:
          return 'name';
      }
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.alarm-point-hidden) {
  display: none;
}

.alarm-definition__tabs {
  //margin: 1.5rem -10px 0.5rem -10px;
  text-align: center;
  height: 30px;
  font-size: 10px !important;
}


::v-deep(.p-datatable) {
  .p-datatable-header {
    padding: 10px;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 10px;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
    padding: 10px;
    background-color: #f8f9fa;
    padding-left: 10px !important;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
    padding: 5px;
    font-weight: 1500;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

::v-deep(.p-treetable) {

  .p-treetable-header {
    padding: 10px;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 10px;
  }

  .p-treetable-thead > tr > th {
    text-align: left;
    padding: 10px;
  }

  .p-treetable-tbody > tr > td {
    cursor: auto;
    padding: 5px;
    font-weight: 1500;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }

}

.w-20-rem {
  width: 20rem;
}
</style>
